import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  menu_is_open = false;
  sign_is_open = false;
  register_is_open = false;

  constructor(public router: Router) { }

  toggleMenu() {
    this.menu_is_open = !this.menu_is_open;
  }

  toggleSign() {
    this.closeRegister();
    this.sign_is_open = !this.sign_is_open;
  }

  toggleRegister() {
    this.closeSign();
    this.register_is_open = !this.register_is_open;
  }

  closeAll() {
    this.closeSign();
    this.closeRegister();
  }

  closeSign() {
    this.sign_is_open = false;
  }

  closeRegister() {
    this.register_is_open = false;
  }

  goToLink(url) {
    location.href = url;
  }

}
