import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  isLoading = new BehaviorSubject<boolean>(false);

  constructor() { }

  get loading(): BehaviorSubject<boolean> {
    return this.isLoading;
  }

}
