import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MessageService, ConfirmationService } from 'primeng/api';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private messageService: MessageService, private confirmationService: ConfirmationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                this.messageService.clear();
                if (error.status === 500) {
                    this.confirm('Algo inesperado aconteceu!', 'Um problema ocorreu em nosso servidor. <br>Por favor, tente mais tarde.');
                } else if (error.status === 504 || error.status === 502) {
                    this.confirm('Algo inesperado aconteceu!', 'Não foi possível estabelecer uma conexão com o servidor. <br>Por favor, tente mais tarde.');
                } else {
                    console.log(error);
                    console.log(error.error);
                    const errors: Array<any> = error.error;
                    if (errors && errors.length) {
                        let messages = '';
                        errors.forEach(element => {
                            messages += '<p>' + element.message + '</p>';
                        });
                        this.messageService.add({ severity: 'error', summary: 'Atenção', detail: messages });
                    }
                }
                return throwError(error);
            })
        );
    }

    private confirm(header: string, message: string) {
        this.confirmationService.confirm({
            header: header,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Entendi',
            rejectVisible: false,
            message: message
        });
    }

}
