import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', loadChildren: './modules/home/home.module#HomeModule' },
  { path: 'quem-somos', loadChildren: './modules/quem-somos/quem-somos.module#QuemSomosModule' },
  { path: 'empresa', loadChildren: './modules/empresa/empresa.module#EmpresaModule' },
  { path: 'vagas', loadChildren: './modules/vagas/vagas.module#VagasModule' },
  { path: 'artigos', loadChildren: './modules/artigos/artigos.module#ArtigosModule' },
  { path: 'fale-conosco', loadChildren: './modules/fale-conosco/fale-conosco.module#FaleConoscoModule' },
  { path: 'politica-privacidade', loadChildren: './modules/politica-privacidade/politica-privacidade.module#PoliticaPrivacidadeModule' },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
