import { Injectable } from '@angular/core';
import { HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable, empty } from 'rxjs';

import { LoaderService } from '../loader/loader.service';
import { environment } from 'src/environments/environment.prod';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    private requests: HttpRequest<any>[] = [];

    constructor(private loaderService: LoaderService) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.canIntercept(req)) {
            this.requests.push(req);
            this.loaderService.isLoading.next(true);
            return Observable.create(observer => {
                const subscription = next.handle(req)
                    .subscribe(event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    }, err => {
                        this.removeRequest(req);
                        observer.error(err);
                    }, () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
                return () => {
                    this.removeRequest(req);
                    subscription.unsubscribe();
                };
            });
        } else {
            return next.handle(req);
        }
    }


    private canIntercept(request: HttpRequest<any>): boolean {
        return !this.ignorePath(request, 'vaga');
    }

    private ignorePath(request: HttpRequest<any>, startsWith: string, endsWith?: string): boolean {
        let result = false;
        result = request.url.startsWith(`${environment.apiUrl}/${startsWith}`);
        if (endsWith) {
            result = request.url.startsWith(`${environment.apiUrl}/${startsWith}`) && request.url.endsWith(endsWith);
        }
        return result;
    }

}
